.word {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
}

.word-letters {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: space-between;
}