body {
  background-color: #282c34;
}

.app {
  text-align: center;
  background-color: #282c34;
  height: 100%;
}

.app-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .app-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.name-selection {
  max-width: 50vw;
}

.score-names {
  max-height: 5vh;
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;

  margin-bottom: 3vh;

  /* Firefox */
  -moz-transition: all 0.5s ease-in;
  /* WebKit */
  -webkit-transition: all 0.5s ease-in;
  /* Opera */
  -o-transition: all 0.5s ease-in;
  /* Standard */
  transition: all 0.5s ease-in;
}

.score-names.hidden {
  max-height: 0vh;
  opacity: 0;
}

.score-names .name-input {
  min-width: 40vw;
}

.add-names {
  max-height: 5vh;
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;

  margin-bottom: 3vh;
}

.app-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.nav {
  background-color: #282c34;
  min-height: 90vh;
}

.app-link {
  color: #61dafb;
}

.app-footer {
  background-color: #282c34;
  min-height: 3vh;
}

@keyframes app-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.name-entry {
  color: white;
}