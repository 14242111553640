.letter-tile {
    min-width: 40px;
    min-height: 40px;
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 24px;
    color: #282c34;
    background-color: white;
    border-radius: 5px;
    margin-left: 5px;

    /* Firefox */
    -moz-transition: all 1s ease-in;
    /* WebKit */
    -webkit-transition: all 1s ease-in;
    /* Opera */
    -o-transition: all 1s ease-in;
    /* Standard */
    transition: all 1s ease-in;
}

.compact .letter-tile{
    min-width: 0;
    border-radius: 0px;
    margin-left: 0px;
    color: white;
    background-color: #282c34;
}